import Swiper, {Autoplay, EffectCoverflow, EffectFade, Navigation, Pagination} from 'swiper';
import GLightbox from "glightbox";

document.addEventListener("DOMContentLoaded", function (event) {

    // INIT MENUBUTTON
    const menu_button = document.querySelector('[data-behaviour="toggle-menu"]');
    menu_button.addEventListener('click', () => {
        document.body.classList.toggle('body--show');
    })

    // INIT SEARCHBUTTON
    const search_button = document.querySelector('[data-behaviour="toggle-search"]');
    if (search_button) {
        const search_input = search_button.parentNode.querySelector('input');
        search_input.tabIndex = -1;
        search_button.addEventListener('click', (event) => {
            if (!document.body.classList.contains('body--search')) {
                event.preventDefault();
                document.body.classList.add('body--search');
                search_input.tabIndex = 0;
                search_button.ariaExpanded = true;
                search_input.focus();
                search_button.ariaLabel = "Suchen";
                return;
            }
        });

        document.addEventListener('click', e => {
            if (!search_button.parentNode.contains(e.target)) {
                document.body.classList.remove('body--search');
                search_input.tabIndex = -1;
                search_button.ariaExpanded = false;
                search_button.ariaLabel = "Suche umschalten";
            }
        });

        //Check if focus is outside the language list
        document.addEventListener('keydown', e => {
            if (e.key === 'Escape') {
                document.body.classList.remove('body--search');
                search_input.tabIndex = -1;
                search_button.ariaExpanded = false;
                search_button.ariaLabel = "Suche umschalten";
            }
        });

        //Check if focus with tab is outside the language list
        document.addEventListener('focusin', e => {
            if (!search_button.parentNode.contains(e.target)) {
                document.body.classList.remove('body--search');
                search_input.tabIndex = -1;
                search_button.ariaExpanded = false;
                search_button.ariaLabel = "Suche umschalten";
            }
        });
    }

    const showHide = document.querySelectorAll('[data-behaviour="show-hide"]');
    showHide.forEach((element) => {
        element.addEventListener('click', () => {
            element.classList.toggle('show-hide--active');
            element.ariaExpanded = !element.ariaExpanded;
        });
    });

    // INIT LIGHTBOX
    const lightbox = GLightbox({
        selector: '[data-gallery="gallery"]',
        touchNavigation: true,
        loop: true,
    });

    // INIT SWIPER
    const sliders = document.querySelectorAll('.swiper');
    sliders.forEach(function (slider) {
        const autoSwiper = slider.classList.contains('swiper--auto');
        const swiper = new Swiper(slider, {
            // configure Swiper to use modules
            modules: [Pagination, Navigation, Autoplay, EffectFade],
            effect: 'slide',
            fadeEffect: {
                crossFade: true
            },
            direction: 'vertical',
            loop: true,

            autoplay: autoSwiper ? {
                delay: 5000,
            } : false,

            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
        });
    });

    // FIXED HEADER
    window.addEventListener('scroll', () => {
        if (document.documentElement.scrollTop > 30 || document.body.scrollTop > 30){
            document.body.classList.add('menu--fixed');
        } else {
            document.body.classList.remove('menu--fixed');
        }
    });

    const articlepage = document.querySelector('.articlepage');
    if (articlepage) {
        const articlepage_sidebar = articlepage.querySelector('.articlepage_content_sidebar');
        const articlepage_content = articlepage.querySelector('.articlepage_content_elements');

        // Get all the headings in the content and create a list of links in the sidebar
        const headings = articlepage_content.querySelectorAll('h2');
        const sidebar = document.createElement('ul');
        sidebar.classList.add('articlepage_content_sidebar_list');
        headings.forEach(heading => {
            const item = document.createElement('li');
            const link = document.createElement('a');
            link.textContent = heading.textContent;
            link.addEventListener('click', (event) => {
                event.preventDefault();
                const offsetTop = heading.offsetTop - 100;
                window.scrollTo({
                    top: offsetTop,
                    behavior: 'smooth'
                });
            });
            item.appendChild(link);

            //Get subheadings
            const subheadings = heading.nextElementSibling.querySelectorAll('h3');
            if (subheadings.length > 0) {
                const sublist = document.createElement('ul');
                sublist.classList.add('articlepage_content_sidebar_sublist');
                subheadings.forEach(subheading => {
                    const subitem = document.createElement('li');
                    const sublink = document.createElement('a');
                    sublink.textContent = subheading.textContent;
                    sublink.addEventListener('click', (event) => {
                        //scroll to the subheading
                        event.preventDefault();
                        const offsetTop = subheading.offsetTop - 100;
                        window.scrollTo({
                            top: offsetTop,
                            behavior: 'smooth'
                        });
                    });
                    subitem.appendChild(sublink);
                    sublist.appendChild(subitem);
                });
                item.appendChild(sublist);
            }

            sidebar.appendChild(item);
        });
        articlepage_sidebar.appendChild(sidebar);
    }


    // Language Toggle
    const languageToggle = document.querySelector('[data-behaviour="toggle-languages"]');
    const languagelist = document.querySelector('[data-behaviour="language-list"]');

    if(languageToggle && languagelist) {
        languageToggle.addEventListener('click', () => {
            if (!languagelist.classList.contains('language-list--open')) {
                showLanguageMenu(languagelist, languageToggle);
            } else {
                hideLanguageMenu(languagelist, languageToggle);
            }
        });

        //Check if focus is outside the language list
        document.addEventListener('click', e => {
            if (!languageToggle.parentNode.contains(e.target) && !languagelist.contains(e.target)) {
                hideLanguageMenu(languagelist, languageToggle);
            }
        });

        //Check if focus is outside the language list
        document.addEventListener('keydown', e => {
            if (e.key === 'Escape') {
                hideLanguageMenu(languagelist, languageToggle);
            }
        });

        //Check if focus with tab is outside the language list
        document.addEventListener('focusin', e => {
            if (!languageToggle.parentNode.contains(e.target) && !languagelist.contains(e.target)) {
                hideLanguageMenu(languagelist, languageToggle);
            }
        });
    }
});

function showLanguageMenu(languagelist, languageToggle)
{
    languagelist.hidden = false;
    languagelist.classList.add('language-list--open');
    languageToggle.ariaExpanded = true;
    languagelist.querySelector('a').focus();
    const links = languagelist.querySelectorAll('a');
    links.forEach((link, index) => {
        link.tabIndex = 0;
    });
}

function hideLanguageMenu(languagelist, languageToggle)
{
    languagelist.hidden = true;
    languagelist.classList.remove('language-list--open');
    languageToggle.ariaExpanded = false;
    const links = languagelist.querySelectorAll('a');
    links.forEach((link, index) => {
        link.tabIndex = -1;
    });
}
